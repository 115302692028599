export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Troca Vale-Gas",
				root: true,
				icon: "fas fa-handshake",
				page: "troca",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Trocas",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
				]
			},
		]
	}
};
